<script>
import { mapGetters } from 'vuex'
import stepsMixin from '@/mixins/stepsMixin'
export default {
	mixins: [stepsMixin],
	components: {
		SvgIcon: () => import('@/components/SvgIcon'),
	},

	data() {
		return {
			query: '',
			clients: [],
			resultsShow: false
		}
	},

	computed: {
		...mapGetters({
			clientSearch: 'arceClients/searchClients'
		})
	},

	methods: {
		search: _.debounce(function () {
			let vm = this
			let clients = vm.clientSearch(vm.query)
			vm.clients = clients.map((client) => {return {...client}})
			vm.resultsShow = true
		}, 1000),

		goToMenu(data) {
			this.hiddenSteps()
			this.$router.push({name: 'arce-profile-client', params: {id: data.id}})
			this.resultsShow = false
		},
		setQuery(data) {
			this.query = data
			// this.search()
		}
	},

	mounted() {
		const vm = this
		const $body = document.body
		$body.addEventListener('click', () => {
			vm.resultsShow = false
		})
		vm.eventBusOn('searchClient', vm.setQuery)
		vm.eventBusOn('loadSearchClient', vm.search)
	}
}
</script>
<template>
	<header class="s-header px-3">
		<div class="s-header__logo">
			<img src="@/assets/simulator/logo.png" alt="">
		</div>
		<form @submit.prevent="search" class="s-header__search" @click.stop="">			
			<div class="d-flex s-header__search-query " data-v-step="searchClient">
				<svg-icon class="s-text-muted" name="search"/>
				<input class="header__search-input" placeholder="Search..." type="text" @keyup="search" @focus="resultsShow = true" v-model="query" >
				<div v-if="resultsShow" class="s-header__search-results" @click.stop="" data-v-step="resultSearch">
					<ul>
						<li v-for="(client, index) in clients" :key="`r_client_${index}_${client.code}`" @click="goToMenu(client)">
							<div class="d-flex align-items-center">
								<div class="s-query__icon">
									<img src="@/assets/simulator/account_120.png" alt="">
								</div>
								<div>
									<h5 class="s-query__title m-0">
										<text-highlight :queries="[query]">{{client.nombre}} {{ client.bbvaid }}</text-highlight>
									</h5>
									<p class="s-query__type m-0">{{client.type}}</p>
								</div>
							</div>
						</li>
					</ul>
				</div>
			</div>
		</form>
		<div class="s-header__nav d-flex">
			<div class="px-1">
				<div class="s-dropdown">
					<span class="s-dropdown__content">
						<svg-icon class="s-text-muted" name="favorite"/>
					</span>
					<span class="s-dropdown__trigger">
						<svg-icon name="down" />
					</span>
				</div>
			</div>
			<div class="px-2">
				<button class="btn btn-secondary btn-rounded py-0 px-0 btn-header">
					<svg-icon name="add" />
				</button>
			</div>
			<div class="px-2">
				<button class="btn btn-transparent btn-rounded py-0 px-0 btn-header">
					<svg-icon name="trailhead_alt" />
				</button>
			</div>
			<div class="px-2">
				<button class="btn btn-transparent btn-rounded py-0 px-0 btn-header">
					<svg-icon name="question" />
				</button>
			</div>
			<div class="px-2">
				<button class="btn btn-transparent btn-rounded py-0 px-0 btn-header">
					<svg-icon name="notification" />
				</button>
			</div>			
			<div class="px-2">
				<div class="s-header__avatar">
					<img src="@/assets/simulator/profile_avatar_96.png" alt="">
				</div>
			</div>
		</div>
	</header>
</template>